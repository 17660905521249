import React from 'react';
import { useState } from 'react';
import useConversation from '../zustand/useConversation';
import toast from 'react-hot-toast';

const useSendMessage = () => {
    const [loadingSendingMessage, setLoading] = useState(false);
    const { messages, setMessages, selectedConversation } = useConversation();

    const sendMessage = async (message) => {
        console.log("we are here", message);
        console.log("Check the id", selectedConversation._id);
      
        try {
          const token = localStorage.getItem('token');
          setLoading(true);
      
          const res = await fetch(`https://semer-le-present-f32d8fb5ce8e.herokuapp.com/api/messages/send/${selectedConversation._id}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({ message }), // Send a single message object
          });
      
          const data = await res.json();
          if (data.error) throw new Error(data.error);
      
          setMessages([...messages, data]); // Add the returned message to the state
        } catch (error) {
          toast.error(error.message);
        } finally {
          setLoading(false);
        }
    };

    return { loadingSendingMessage  ,sendMessage };
};

export default useSendMessage;
