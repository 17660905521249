import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast' ; 
const useGetConversations = () => {
    const [loading,setLoading] = useState(false);
    const [conversations,setConversations] = useState([]) ;
    useEffect(() => {
      const getConversations = async () => { 
        console.log("function called ") ; 
        setLoading(true) ;   
        try {
          const token = localStorage.getItem('token'); 
            const  res = await fetch('https://semer-le-present-f32d8fb5ce8e.herokuapp.com/api/user/usersSideBar',{
           
              headers:{
                'Authorization': `Bearer ${token}`,
              },
            }) ;
            const data = await res.json();
            if(data.error){
              throw new Error(data.error);
            }
            console.log("data ==>" , data) ; 
            setConversations(data);
          } catch (error) {
            toast.error(error.message) ; 
          }finally{
            setLoading(false) ; 
          }
      }
      getConversations(); 
    }, [])
    return {loading , conversations} ;         
}


export default useGetConversations
