import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import LoginPage from './LoginPage';
import Home from './Home';
import FormDialog from './FormDialog';
import SignupDialog from './SignupDialog';
import './style/App.css';

function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}

function App() {
  const [open, setOpen] = useState(false);
  const [openSignup, setOpenSignup] = useState(false);
  const location = useLocation();

  const handleClickOpen = () => setOpen(true);
  const handleClickClose = () => setOpen(false);
  const handleClickOpenSignup = () => setOpenSignup(true);
  const handleCloseSignup = () => setOpenSignup(false);

  return (
    <div>
      <div>
        <Routes>
          <Route
            path='/'
            element={
              <>
                <header className='header'>
                  <div className='login-container'>
                    <LoginPage handleClickOpenSignup={handleClickOpenSignup} />
                  </div>
                </header>
                <SignupDialog openSignup={openSignup} handleClos  eSignup={handleCloseSignup} />
              </>
            }
          />
          <Route
            path='/dashboard'
            element={
              <>
                <Home />
                <FormDialog open={open} handleClose={handleClickClose} />
              </>
            }
          />
        </Routes>
      </div>
    </div>
  );
}

export default AppWrapper;
