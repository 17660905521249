import React, { useEffect, useState } from 'react';
import MUIDataTable from 'mui-datatables';
import { Button, createTheme, ThemeProvider, Box } from "@mui/material";
import axios from 'axios';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ReplayIcon from '@mui/icons-material/Replay';

const UsersTasksTable = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('https://semer-le-present-f32d8fb5ce8e.herokuapp.com/api/users/allUsersFinishedEvents', {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        const formattedData = response.data.flatMap(user => 
          user.finishedEvents.map(event => ({
            Nom: user.username,
            mail: user.email,
            tache: event.title,
            eventId: event._id, // Store event ID in data for easy identification
            action: (
              <Box sx={{ display: 'flex', gap: 2 }}>
                <Button 
                  variant="contained" 
                  color="success"
                  endIcon={<CheckCircleIcon />}
                  sx={{ borderRadius: '20px', padding: '5px 15px', textTransform: 'none' }}
                  onClick={() => verifyTask(event._id)}
                >
                  Tâche vérifier
                </Button>

                <Button 
                  variant="outlined" 
                  color="error"
                  endIcon={<ReplayIcon />}
                  sx={{ borderRadius: '20px', padding: '5px 15px', textTransform: 'none' }}
                  onClick={() => redoTask(event._id)}
                >
                  Tâche à refaire
                </Button>
              </Box>
            )
          }))
        );

        setData(formattedData);
      } catch (error) {
        console.error("Error fetching tasks:", error);
      }
    };

    fetchData();
  }, []);

  const verifyTask = async (taskId) => {
    try {
      const token = localStorage.getItem('token');
      
      const eventDateResponse = await axios.get('https://semer-le-present-f32d8fb5ce8e.herokuapp.com/api/users/eventDate', {
        headers: { 'Authorization': `Bearer ${token}` },
        params: { eventId: taskId },
      });

      const status = eventDateResponse.data.status;

      const dataToUpdate = {
        eventId: taskId,
        completedTask_onTime: status === "ONTIME" ? "true" : "false",
        completedTask_lateFinished: status === "LATE" ? "true" : "false",
      };

      await axios.post('https://semer-le-present-f32d8fb5ce8e.herokuapp.com/api/users/Completedevents', dataToUpdate, {
        headers: { 'Authorization': `Bearer ${token}` },
      });

      // Update the data state to remove the verified task
      setData(prevData => prevData.filter(event => event.eventId !== taskId));
    } catch (error) {
      console.error("Error on processing completing tasks:", error);
    }
  };

  const redoTask = async (taskId) => {
    console.log("Task to be redone:", taskId);
    try {
      const token = localStorage.getItem('token'); // Retrieve JWT token
      
      await axios.post(
        'https://semer-le-present-f32d8fb5ce8e.herokuapp.com/api/users/RedoTheEvents',
        { eventId: taskId },  // Data to send
        {
          headers: { 'Authorization': `Bearer ${token}` }, // Separate headers object
        }
      );
      
      // Update the data state to remove the verified task
      setData(prevData => prevData.filter(event => event.eventId !== taskId));
    } catch (error) {
      console.error("Error on processing redo task:", error);
    }
  };
  

  const columns = [
    { name: "Nom", label: "Nom", options: { filter: true, sort: true } },
    { name: "mail", label: "Email", options: { filter: true, sort: true } },
    { name: "tache", label: "Tâche", options: { filter: true, sort: true } },
    { name: "action", label: "Action", options: { filter: false, sort: false } },
  ];

  const options = {
    responsive: "standard",
    selectableRows: 'none',
    elevation: 0,
    rowsPerPage: 10,
    rowsPerPageOptions: [5, 10, 15],
  };

  const getMuiTheme = () =>
    createTheme({
      components: {
        MuiPaper: {
          styleOverrides: {
            root: {
              backgroundColor: 'white',
              boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
              borderRadius: '8px',
            },
          },
        },
        MuiTableCell: {
          styleOverrides: {
            head: {
              backgroundColor: '#f5f5f5',
              color: '#333',
              fontWeight: 'bold',
              fontSize: '1rem',
              padding: '10px',
              borderBottom: '2px solid #e0e0e0',
            },
            body: {
              fontSize: '0.9rem',
              color: '#555',
              padding: '10px',
              borderBottom: '1px solid #e0e0e0',
            },
          },
        },
        MuiButton: {
          styleOverrides: {
            root: {
              textTransform: 'none',
              borderRadius: '20px',
              padding: '5px 15px',
              boxShadow: 'none',
              '&:hover': {
                boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)',
              },
            },
          },
        },
      },
    });

  return (
    <ThemeProvider theme={getMuiTheme()}>
      <MUIDataTable
        title={"Liste des Tâches"}
        data={data}
        columns={columns}
        options={options}
      />
    </ThemeProvider>
  );
}

export default UsersTasksTable;
